import * as React from 'react';
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import {ColorPaletteImage} from "../ColorPaletteImage";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import axios from "axios";
import {AnimatePresence, motion} from "framer-motion";
import {transition} from "../../constants/transitions";
import CircularProgressComponent from "../CircularProgressComponent";
import {CurrentPaletteType} from "../../types/CurrentPaletteType";
const style = {


    backgroundColor: 'white',
    boxShadow: 24,
    borderRadius: 4,
};

type ModalPaletteType = {
    imageObject?:any;
    urlImage?: string;
    openModal: boolean;
    handleModal: any;

    user?: string;
    pageURL?: string;
}

export const useModalPalette = () => {
    const [ openModal, setOpenModal ] = React.useState(false);

    const handleModal = () => {
        setOpenModal( !openModal );
    }

    return {
        openModal,
        handleModal,
    }

}

export const ModalPalette = ({ openModal, handleModal, urlImage, pageURL, user, imageObject }:ModalPaletteType) => {
    const [ image, setImage ] = React.useState<any>( null );
    const [ selectedColor, setSelectedColor ] = React.useState<string | null>( null );

    React.useEffect(() => {
        if( imageObject ) {
            setImage( imageObject )
        }
    }, [ imageObject ])
    React.useEffect(() => {
        if( !selectedColor ) return;

        setTimeout(() => {
            setSelectedColor(null);
        }, 5000)
    },[ selectedColor ]);

    React.useEffect(() => {
        getImage()
    },[ ]);

    const getImage = async () => {
        if( !urlImage ) return;

        try {
            const result = await axios.get(
                urlImage, {
                    responseType: "arraybuffer"
                }
            );

            const base64 = btoa(
                new Uint8Array( result.data ).reduce(
                    (data,byte) => data + String.fromCharCode(byte),''
                )
            )

            setImage( `data:;base64,${base64}` );
        }catch (e) {
            console.log(e)
        }

    }

    const notification = (color:string) => {
        return(
            <>
                <motion.div
                    style={{
                        position: 'absolute',
                        zIndex: 10,
                        bottom: -90
                    }}
                    exit={{
                        opacity: 0,
                        transition: {
                            ...transition
                        }
                    }}
                    initial={{
                        opacity: 0,
                        bottom: -90
                    }}
                    animate={{
                        opacity: 1,
                        bottom: -100,
                        transition: {
                            ...transition,
                        }
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: 'black',
                            borderRadius: 8,
                            border: '1px solid white',
                            width: 300,
                            p: 1,
                            justifyContent: 'center',
                            display: 'flex',

                        }}
                    >
                        <Typography sx={{ textAlign: 'center' }} color="text.primary" variant="caption">
                            El color { color } ha sido copiado correctamente.
                        </Typography>
                    </Box>
                </motion.div>
            </>


        )
    }
    return(
        <>

            <Modal
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
                open={ openModal }
                onClose={ handleModal }
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <motion.div
                    style={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%) scale(0.9)',
                        width: 300,
                    }}
                    initial={{
                        opacity: 0,
                        transform: 'scale(0.9) translate(-50%, -50%)'
                    }}
                    animate={{
                        opacity: 1,
                        transform: 'scale(1) translate(-50%, -50%)',
                        transition: {
                            ...transition
                        }
                    }}
                >
                    <AnimatePresence>
                        {
                            selectedColor && (
                                notification( selectedColor )
                            )
                        }
                    </AnimatePresence>

                    <Card sx={ style }>
                        <Box
                            sx={{ position: 'relative', height: 200 }}
                        >
                            {
                                image ? (
                                    <motion.div
                                        initial={{
                                            opacity: 0
                                        }}
                                        animate={{
                                            opacity: 1,
                                            transition: {
                                                ...transition
                                            }
                                        }}
                                    >

                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                width: '100%',
                                                height: '100%',
                                                background: 'linear-gradient(0deg, rgba(0,0,0,0.7245098723082983) 0%, rgba(0,0,0,0) 45%)',
                                                zIndex: 2
                                            }}
                                        />
                                        {
                                            user && (
                                                <Typography
                                                    onClick={ () => {
                                                        if ( !pageURL ) return;

                                                        window.open(pageURL, '_blank')
                                                    } }
                                                    sx={{
                                                        cursor: 'pointer',
                                                        textDecoration: pageURL ? 'underline' : 'none',
                                                        fontSize: 10,
                                                        color: 'white',
                                                        position: 'absolute',
                                                        bottom: 4,
                                                        left: 8,
                                                        zIndex: 3,
                                                    }}
                                                >
                                                    By { user }
                                                </Typography>
                                            )
                                        }
                                        <CardMedia
                                            sx={{
                                                borderRadius: '8px 8px 0 0',
                                                mb: 0,
                                                height: 200,
                                                width: '100%',
                                                objectFit: 'cover',
                                                flex: 2,
                                                zIndex: 1
                                            }}
                                            component="img"
                                            image={ image }
                                            alt="image-alt"
                                        />
                                    </motion.div>

                                ) : (
                                    <CircularProgressComponent/>
                                )
                            }

                        </Box>
                        <Box sx={{ height: 100 }}>
                            {
                                image && (
                                    <motion.div
                                        initial={{
                                            opacity: 0,
                                            y: -10
                                        }}
                                        animate={{
                                            opacity: 1,
                                            y: 0,
                                            transition: {
                                                ...transition
                                            }
                                        }}
                                    >
                                        <ColorPaletteImage
                                            modal={ true }
                                            setSelectedColor={ setSelectedColor }
                                            src={ image }
                                        />
                                    </motion.div>
                                )
                            }
                        </Box>



                    </Card>

                </motion.div>
            </Modal>
        </>
    )
}
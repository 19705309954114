import * as React from 'react';
import {CardActionArea, Container, Stack, Tab, Tabs, Typography, Paper} from "@mui/material";
import {CustomizedSearchInput, useCustomizedSearchInput} from "../../components/CustomizedSearchInput";
import withTheme from "../../components/WithTheme";
import {usePixabayFinder} from "../../components/common/PixabayFinder";
import Grid from "@mui/material/Grid";
import ImageResultCard from "../../components/common/ImageResultCard";
import {motion} from "framer-motion";
import {transition} from "../../constants/transitions";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import StyledButton from '../../styled/StyledButton';
import Layout from './../../components/layout';
import Seo from  './../../components/seo';
import {navigate} from "gatsby";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CircularProgressComponent from "../../components/CircularProgressComponent";
import Box from "@mui/material/Box";
import {UploadFile, useUploader} from "../../components/UploadFile";
import {ModalPalette, useModalPalette} from "../../components/common/ModalPalette";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const inputTransition = {
    initial: {
        opacity:0,
        x: 20,
    },
    animate: {
        opacity: 1,
        x: 0,
        transition: {...transition, duration: 3 }
    }
}

export const enteringFormTransition = {
    initial: {
        opacity:0,
        y: 10
    },
    animate: {
        opacity:1,
        y: 0,
        transition: {
            delayChildren: .6,
            staggerChildren: .1,
            staggerDirection: 1,
        }
    }
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ mt: 4 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const Colors = () => {
    const { searchQuery, setSearchQuery } = useCustomizedSearchInput();
    const [value, setValue] = React.useState(0);
    const {totalPages, pixabayResults, arrayRef, setSearchOptions, searchOptions, setPixabayResults, loadingResults } = usePixabayFinder( process.env.GATSBY_APP_PIXABAY_KEY )
    const { dataUri, onChange, handleDelete } = useUploader(false);
    const { handleModal, openModal } = useModalPalette();

    React.useEffect(() => {
        if( dataUri ) {
            handleModal();
        }
    }, [ dataUri ])
    React.useEffect(() => {
        if( searchQuery === undefined ) {
            setPixabayResults( [] );
        }
    },[ searchQuery ]);

    const handleSearch = async () => {
        if( !searchQuery ) return;
        setSearchOptions((prev:any) => ({
            ...prev,
            query: searchQuery
        }))
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const advertisement = () => (
        <Grid justifyContent="center" maxWidth="md" container>
            <Grid item>
                <motion.div
                    initial={{
                        y: 20,
                        opacity: 0
                    }}
                    animate={{
                        y: 0,
                        opacity: 1,
                        transition: {
                            ...inputTransition,
                            delay: 10,
                        }
                    }}
                >
                    <Card sx={{ mt: 8, mb: 4 }} elevation={ 4 }>
                        <CardActionArea onClick={ () => navigate('/')}>
                            <CardContent>
                                <Stack>
                                    <Typography sx={{ mb: 1 }} color="text.primary" variant="body2">
                                        ¡Un perfil para profesionales cómo tú!
                                    </Typography>
                                    <Typography color="text.secondary" variant="caption">
                                        Comparte tus redes sociales, servicios y hobbies en un sólo
                                        link y personalízalo a tu gusto.
                                    </Typography>
                                    <Button sx={{
                                        mt: 1,
                                        borderRadius: 3.5,
                                        textTransform: 'none',
                                    }} size="small" color="secondary" variant="outlined">
                                        ¡Comienza gratis!
                                    </Button>
                                </Stack>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </motion.div>
            </Grid>
        </Grid>

    )

    // @ts-ignore
    return(
        <Layout
            noFooter
        >
            <Seo
                title="Paletas de colores para tus proyectos"
                description="Encuentra inspiración."
            />
            <Container maxWidth="sm" sx={{ mt: 10, overflowX: 'hidden' }}>
                <Typography sx={{ mb: 2 }} color="text.primary" align="left" variant="h5">
                    Paletas de colores para tus proyectos.
                </Typography>
                <Typography sx={{ mb: 4 }} align="left" color="text.secondary" variant="body2">
                    Busca o sube una imagen para comenzar.
                </Typography>
                <Tabs value={value} onChange={handleChange} centered>
                    <Tab sx={{ textTransform: 'none' }} label="Buscar" {...a11yProps(0)}/>
                    <Tab sx={{ textTransform: 'none' }} label="Subir" {...a11yProps(1)}/>
                </Tabs>

                <TabPanel value={value} index={0}>
                    <Box>
                        <CustomizedSearchInput
                            placeholder="Busca por palabra clave..."
                            handleSearch={ handleSearch }
                            searchQuery={ searchQuery }
                            setSearchQuery={ setSearchQuery }
                        />
                        <Typography
                            sx={{
                                color:'rgba(255,255,255,0.4)',
                                textDecoration: 'underline',
                                cursor:'pointer'
                            }}
                            variant="caption"
                            onClick={ () => window.open(`https://pixabay.com/`, '_blank')}
                        >
                            Powered by Pixabay
                        </Typography>
                        <motion.div initial='initial' animate='animate' exit='exit'>
                            <motion.span variants={{ ...enteringFormTransition, }}>
                                <Grid container>
                                    {
                                        loadingResults ? (
                                            <CircularProgressComponent/>
                                        ) : (
                                            <>
                                                {
                                                    pixabayResults.map((value:any, index:number) => {
                                                        return(
                                                            <Grid item xs={ 12 }>
                                                                <motion.div variants={inputTransition}>
                                                                    <ImageResultCard
                                                                        user={ value.user }
                                                                        arrayRef={ arrayRef }
                                                                        largeImageURL={ value.largeImageURL }
                                                                        pageURL={ value.pageURL }
                                                                        urlImage={ value.previewURL }
                                                                        darkMode={ false }
                                                                        index={ index }
                                                                    />
                                                                </motion.div>
                                                            </Grid>
                                                        )})
                                                }
                                            </>
                                        )
                                    }
                                </Grid>
                            </motion.span>
                        </motion.div>
                        {
                            pixabayResults.length > 0 && (
                                <>
                                    <Grid spacing={ 2 } sx={{ justifyContent: 'center', my: 4 }} container>
                                        {
                                            searchOptions.page > 1 && (
                                                <Grid item>
                                                    <StyledButton
                                                        variant="outlined"
                                                        startIcon={ <ChevronLeft/> }
                                                        onClick={ () => {
                                                            setSearchOptions((prev:any) => ({
                                                                ...prev,
                                                                page: searchOptions.page - 1
                                                            }))
                                                        }}
                                                    >
                                                        Atrás
                                                    </StyledButton>
                                                </Grid>
                                            )
                                        }
                                        {
                                            searchOptions.page < totalPages && (
                                                <Grid item>
                                                    <StyledButton
                                                        variant="contained"
                                                        onClick={ () => {
                                                            setSearchOptions((prev:any) => ({
                                                                ...prev,
                                                                page: searchOptions.page + 1
                                                            }))
                                                        }}
                                                        endIcon={ <ChevronRight/> }
                                                    >
                                                        Siguiente
                                                    </StyledButton>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </>
                            )
                        }

                        { advertisement() }
                    </Box>
                </TabPanel>
                <TabPanel index={1} value={value}>
                    <UploadFile title="Sube una imagen" dataUri={ dataUri } onChange={ onChange } handleDelete={ handleDelete }/>
                    <ModalPalette imageObject={ dataUri } openModal={ openModal } handleModal={ handleModal }/>
                    {
                        advertisement()
                    }
                </TabPanel>
            </Container>
        </Layout>
    )
};

export default withTheme( Colors );
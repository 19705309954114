import * as React from 'react';
import {InputBase, Paper} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import {Clear} from "@mui/icons-material";

export const useCustomizedSearchInput = () => {
    const [ searchQuery, setSearchQuery ] = React.useState<string | undefined>( undefined );

    return {
        searchQuery,
        setSearchQuery,
    }
}

export const CustomizedSearchInput = (
    {
        handleSearch,
        searchQuery,
        setSearchQuery,
        placeholder
    }:{
        handleSearch: any,
        searchQuery:string | undefined,
        setSearchQuery: any,
        placeholder?:string
    }
) => {
    const textInput = React.useRef(null);

    return(
        <Paper
            sx={{
                borderRadius: 4,
                display: 'flex',
                alignItems: 'center',
                p: '2px 4px'
            }}
        >
            <InputBase
                inputRef={ textInput }
                onKeyDown={ (e) => {
                    if( e.keyCode === 13 ) {
                        handleSearch()
                    }
                } }
                value={ searchQuery }
                onChange={ (e) => setSearchQuery( e.target. value )}
                sx={{
                    ml: 1,
                    flex: 1
                }}
                placeholder={ placeholder ? placeholder : 'Buscar'}
            />
            {
                searchQuery && (
                    <IconButton
                        onClick={ () => {
                            setSearchQuery( undefined );
                            textInput.current.value = "";
                        }}
                        sx={{ p: '10px' }}
                        aria-label="limpiar"
                    >
                        <Clear/>
                    </IconButton>
                )
            }
            <IconButton
                onClick={ handleSearch }
                sx={{ p: '10px' }}
                aria-label="buscar"
            >
                <SearchIcon/>
            </IconButton>
        </Paper>
    )
}
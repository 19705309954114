import axios from "axios";
import * as React from "react";
import {SearchOptions} from "../PixabaySelector";
import {useEffect} from "react";

export const usePixabayFinder = ( apiKey:string ) => {
    const [ arrayRef, setArrayRef ] = React.useState([]);
    const [ pixabayResults, setPixabayResults ] = React.useState([]);
    const [ loadingResults, setLoadingResults ] = React.useState<boolean>( false );
    const [ totalPages, setTotalPages ] = React.useState<number>( 0 );
    const [ searchOptions, setSearchOptions ] = React.useState<SearchOptions>({
        lang: 'es',
        query: '',
        imageType: undefined,
        colors: undefined,
        category: undefined,
        perPage: 6,
        page: 1,
    });

    useEffect(() => {
        if( searchOptions.query !== '' ) {
            handlePixabaySearch()
        }
    },[ searchOptions ]);

    const handlePixabaySearch = async ()  => {
        setLoadingResults( true );
        setPixabayResults([]);

        const fullPath = `https://pixabay.com/api/?key=${ apiKey }${searchOptions.lang && '&lang=' + searchOptions.lang}${ searchOptions.query && '&q=' + searchOptions.query }
        ${searchOptions.perPage && '&per_page=' + searchOptions.perPage}${searchOptions.page && '&page=' + searchOptions.page}`

        try{
            const result = await axios.get(fullPath);

            if(result) {
                const arr:any = [];
                result.data.hits.map((v:any,i:any) => (
                    arr[i] = React.createRef()
                ));

                if( result?.data?.totalHits ) {
                    setTotalPages(  Math.round( result.data.totalHits / searchOptions.perPage ) )
                }
                setArrayRef(arr);
                setPixabayResults( result.data.hits );
                setLoadingResults( false );
            }
        }catch(e) {
            console.log( e );
        }
    }

    return {
        totalPages,
        searchOptions,
        setSearchOptions,
        handlePixabaySearch,
        setPixabayResults,
        pixabayResults,
        loadingResults,
        arrayRef
    }
}
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import { ColorPaletteImage } from "./../ColorPaletteImage";
import PaletteColorsPreview from './PaletteColorsPreview';
import Box from "@mui/material/Box";
import {ModalPalette, useModalPalette} from "./ModalPalette";

const ImageResultCard = ({ setButtonSaveProperties, arrayRef, urlImage, darkMode, index, largeImageURL, isPremium, defaultPalette, item, user, pageURL }:{ largeImageURL:string, arrayRef:any, urlImage:string, darkMode:boolean, index:number, isPremium?:boolean, defaultPalette?:any, item?:any, getThemes?:any, setButtonSaveProperties?:any, user?:string, pageURL?:string }) => {


    const { handleModal, openModal } = useModalPalette();


    return(
        <>
            <ModalPalette
                urlImage={ largeImageURL }
                user={ user }
                pageURL={ pageURL }
                openModal={ openModal }
                handleModal={ handleModal }
            />
            <Card
                sx={{
                    borderBottom: '1px solid rgba(255,255,255,0.1)',
                    position: 'relative',
                    borderRadius: 0,
                }}
                elevation={ 0 }
                key={index}
            >
                <CardActionArea
                    sx={{
                        display: 'flex',
                        p: 2,
                        justifyContent: 'space-around'
                    }}
                    onClick={() => {
                        handleModal()
                        //updateThemeColor(index);
                    }}
                >
                    <CardMedia
                        sx={{
                            borderRadius: 4,
                            mb: 0,
                            height: 100,
                            width: 100,
                            objectFit: 'cover',
                            flex: 2
                        }}
                        component="img"
                        image={ urlImage }
                        alt="image-alt"
                    />
                    <Box
                        sx={{ flex: 1, p: 3 }}
                    >
                        {
                            defaultPalette ?
                                <PaletteColorsPreview data={ defaultPalette }/>
                                :
                                <ColorPaletteImage darkMode={darkMode} ref={arrayRef[index]} src={ urlImage } isPremium={ isPremium }/>
                        }
                    </Box>

                </CardActionArea>
            </Card>
        </>

    )
}

export default ImageResultCard;